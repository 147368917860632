var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSection("submit")
    ? _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.verify-order-accordion-8",
                  modifiers: { "verify-order-accordion-8": true },
                },
              ],
              staticClass: "accordion-header",
              class: { disabled: _vm.sectionIsDisabled("submit") },
              attrs: { "header-tag": "header", role: "tab" },
              on: {
                "!click": function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("set-as-current")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("div", { staticClass: "header-left" }, [
                    _c("h4", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.checkoutButtonLabel) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _c("feather-icon", { attrs: { type: "chevron-right" } }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              ref: "verify-order-accordion-8",
              attrs: {
                id: "verify-order-accordion-8",
                accordion: "verify-order-accordion",
                role: "tabpanel",
                visible: _vm.currentSection === "submit",
              },
            },
            [
              _c(
                "div",
                { staticClass: "checkout-container" },
                [
                  _c("b-card-body", { staticClass: "help-text" }, [
                    _c("p", [
                      _vm._v(
                        "\n          Thank you for verifying the information provided. Your expected filing date is\n          " +
                          _vm._s(_vm.expectedProcessingDate) +
                          ".\n        "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n          Once you click submit, your order will immediately move in line into our processing\n          queue for filing. You may track the status of your order on the Pending Filings\n          section located on your account Home page.\n        "
                      ),
                    ]),
                    _vm.existingOrderItem
                      ? _c("p", [
                          _vm._v(
                            "\n          If after 24 hours your order remains unverified, we will submit it with the\n          information we have to limit delays to your company filing.\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("checkout-cart", {
                    attrs: {
                      "show-tos-url": false,
                      "verify-order-checkout": true,
                    },
                    on: {
                      submit: function ($event) {
                        return _vm.$emit("submit")
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }