<template>
  <b-card
    v-if="showSection('submit')"
    no-body
  >
    <b-card-header
      v-b-toggle.verify-order-accordion-8
      :class="{ disabled: sectionIsDisabled('submit') }"
      header-tag="header"
      class="accordion-header"
      role="tab"
      @click.stop.capture="$emit('set-as-current')"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="header-left">
          <h4>
            {{ checkoutButtonLabel }}
          </h4>
        </div>
        <feather-icon type="chevron-right" />
      </div>
    </b-card-header>
    <b-collapse
      id="verify-order-accordion-8"
      ref="verify-order-accordion-8"
      accordion="verify-order-accordion"
      role="tabpanel"
      :visible="currentSection === 'submit'"
    >
      <div class="checkout-container">
        <b-card-body class="help-text">
          <p>
            Thank you for verifying the information provided. Your expected filing date is
            {{ expectedProcessingDate }}.
          </p>
          <p>
            Once you click submit, your order will immediately move in line into our processing
            queue for filing. You may track the status of your order on the Pending Filings
            section located on your account Home page.
          </p>
          <p v-if="existingOrderItem">
            If after 24 hours your order remains unverified, we will submit it with the
            information we have to limit delays to your company filing.
          </p>
        </b-card-body>
        <checkout-cart
          :show-tos-url="false"
          :verify-order-checkout="true"
          @submit="$emit('submit')"
        />
      </div>
    </b-collapse>
  </b-card>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'SubmitSection',
  components: {
    FeatherIcon:      () => import('@/components/shared/FeatherIcon'),
    CheckoutCart:     () => import('@/components/shared/CheckoutCart'),
  },
  props: {
  },
  data() {
    return {
      allAddOnProducts: [],
      selectedAddOnProducts: [],
      addOnProducts: [],
      removingAllAddOnProducts: false,
      showRemoveProductsWarning: false,
    }
  },
  computed: {
    ...mapGetters('verifyOrder', [
      'currentSection',
      'sectionIsDisabled',
      'showSection',
      'expectedProcessingDate',
      'orderRequiringVerificationType',
    ]),
    checkoutButtonLabel() {
      return this.upsellProductInCart ? 'Submit & Checkout' : 'Submit'
    },
    existingOrderItem() {
      return this.orderRequiringVerificationType === 'OrderItem'
    },
  },
  async mounted() {},
  methods: {
  },
}
</script>

<style lang="scss" scoped>
  .checkout-container {
    padding: 2rem;

    .help-text {
      padding-bottom: 0;
    }
  }

</style>
